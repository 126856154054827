//region Raised

.b-buttongroup.b-raised {
    box-shadow : 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.b-button.b-raised {
    transition-property        : color, box-shadow, background-color;
    transition-duration        : .2s;
    transition-timing-function : cubic-bezier(.25, .8, .25, 1);

    box-shadow                 : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0px rgba(0, 0, 0, 0.14), 0 1px 5px 0px rgba(0, 0, 0, 0.12);

    &.b-pressed {
        box-shadow : 0 2px 1px -1px rgba(0, 0, 0, 0.1);

        &:hover:not(.b-disabled) {
            box-shadow : 0 2px 1px -1px rgba(0, 0, 0, 0.1);
        }
    }

    &.b-disabled {
        box-shadow : none;
    }

    &:hover:not(.b-disabled),
    .b-outer.b-using-keyboard &:focus:not(.b-disabled) {
        box-shadow : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    }

    .b-outer.b-using-keyboard &.b-pressed:focus:not(.b-disabled) {
        box-shadow : 0 3px 1px -2px rgba(0, 0, 0, 0.1);
    }

    &:active:not(.b-disabled) {
        box-shadow : 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
}

//endregion
