@import '../../variables';

// NOTE: Separate file to allow minimal import in Docs browser

//region Custom icons

.b-icon-column-move-left:before {
    content : '\f060';
}

.b-icon-column-move-right:before {
    content : '\f061';
}

.b-icon-group-collapse:before {
    content    : '\f0d7';
    width      : .6em;
    text-align : center;
}

.b-icon-group-expand:before {
    content    : '\f0da';
    width      : .6em;
    text-align : center;
}

.b-icon-sort-asc:before {
    content : '\f062';
}

.b-icon-sort-desc:before {
    content : '\f063';
}

//endregion

@import 'material-columndragtoolbar';

.b-gridbase {
    // no border between cells (horizontally)
    .b-grid-cell,
    .b-grid-merged-cells {
        border-right-color : transparent;
    }
}

.b-grid-header:not(.b-grid-header-parent) .b-grid-header-text {
    // Not full width on Material so that the sort icon is immediately on its right
    > .b-grid-header-text-content {
        flex      : 0 1 auto;
        // Need to override `width: 0` added to make IE resize correctly
        width     : auto;
        // For text-overflow ellipsis to work
        max-width : 100%;
    }
}

.b-grid-header-align-center .b-grid-header-text {
    justify-content : center;
}

.b-grid-header-align-right,
.b-grid-header-align-end {
    .b-grid-header-text {
        justify-content : flex-end;
    }
}

.b-grid-footer-scroller {
    background : $grid-footer-background-color;
}

.b-grid-header {
    .b-button[data-ref="collapseExpand"] {
        position : absolute;
        right    : 0;
    }
}

.b-filter-bar-field {
    margin : 0 0 1em 0;
}
