@import '../../../grid-sass/themes/vars/material-vars';

$event-color-red : #EF5350;
$event-color-pink : #EC407A;
$event-color-purple : #AB47BC;
$event-color-violet : #7E57C2;
$event-color-indigo : #5C6BC0;
$event-color-blue : #42A5F5;
$event-color-cyan : #26C6DA;
$event-color-teal : #26A69A;
$event-color-green : #66BB6A;
$event-color-lime : #D4E157;
$event-color-yellow : #FDD835;
$event-color-orange : #FFA726;
$event-color-gantt-green : #a5d8a7;

$columnlines-major-color : #a0a0a0;

$dependency-color : #ccc;
$dependency-terminal-color : #fff;

$list-dependency-terminal-color : #444;
$list-dependency-terminal-color : darken($list-dependency-terminal-color, 10);

$resourceinfo-icon-color : scale_color($grid-cell-color, $lightness : 30%);
