@import 'vars/material-vars';
@import '../all';

.b-popup.b-schedulerpro-taskeditor {
    .b-divider {
        // There is some insanely long selector applied for inline-flex
        display : none !important;
    }

    .b-tabpanel-body {
        padding-bottom : 0;
    }

    .b-tabpanel-tab {
        min-width : 3em;
    }

    .b-advanced-tab .b-checkbox:not(.b-last-row) {
        margin-top : 1em;
    }
}

.b-calendareditor {
    [data-ref=generalTab] {
        [data-ref=unspecifiedTimeIsWorkingField] {
            margin-inline-start : 0;
        }
    }
}

.b-calendareditor .b-calendareditorbasetab {

    .b-grid[data-ref="grid"] {
        border : 1px solid $field-outer-border-color;
    }

    &.b-calendareditorweektab {
        .b-divider {
            margin-bottom : 1em;
        }

        .b-calendareditor-form.b-content-element {
            grid-template-columns : 1fr 5em 1fr 5em;

            [data-ref="nameField"] {
                grid-column : span 3;
            }

            [data-ref="startDateField"],
            [data-ref="endDateField"] {
                grid-column : span 2;
            }
        }
    }

    .b-calendareditor-form.b-content-element {
        grid-template-columns : auto auto;

        & > .b-field:not(.b-slidetoggle.b-slidetoggle-splitter) {
            display : inline-flex;
            width   : auto;
        }

        .b-span {
            grid-column : 1 / -1;
        }

        .b-fieldset.b-radiogroup {
            margin-block-start : 2em;

            .b-label {
                font-size : 1em;
            }
        }

        .b-slidetoggle.b-slidetoggle-splitter {
            &::before, &::after {
                background : $field-outer-border-color;
            }
        }
    }
}

.b-calendarfield {

    .b-field-inner {
        .b-widget.b-fieldtrigger[data-ref="calendarEditor"] {
            margin-inline-start : 0.3em;
        }
    }

}

